// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import MiddleEllipsis from "react-middle-ellipsis";
// import Moment from 'moment';

import Header from './pages/header/Header';
import Footer from './pages/footer/Footer';
import Banner from './pages/home/Banner';
// import SearchBar from './pages/home/SearchBar';
import EventHighlight from './pages/home/EventHighlight';
import RecSection from './pages/home/RecSection';
import ConcertSection from './pages/home/ConcertSection';
import MusicFesSection from './pages/home/MusicFesSection';
import Login from './pages/user/Login';
import Register from './pages/user/Register';
import Profile from './pages/user/edit-profile/EditProfile';
import ChangePass from './pages/user/edit-profile/ChangePass';
import Cards from './pages/user/edit-profile/Cards';
import UserProfile from './pages/user/UserProfile';
import MainHelpCenter from './pages/help-center/MainHelpCenter';
import SinglePage from './pages/help-center/SinglePage';
import BuyTicketPage from './pages/buy-tickets/Tickets';
import Checkout from './pages/buy-tickets/Checkout';
import Confirmation from './pages/buy-tickets/Confirmation';


import { isAddress } from 'ethers/lib/utils';

// hooks
import { useSignup } from './hooks/useSignup';


function App() {

  // Set state for blockchain data
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);


  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
    const json = await response.json();
    if (response.ok) {
        if (json.length === 1) {
          // Expect only 1 result
          setUserData(json[0]);
        } else {
          console.log("Invalid user data: More than 1 result.");
        }
    }
	};

  const getData = () => {
    if (blockchain.account !== "" && blockchain.huioneToken !== null) {
      fetchUserData();
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);


  return (
    <>
      <Router>
        {/* <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div className="overlay"></div>
        <a href="#0" className="scrollToTop">
          <i className="fas fa-angle-up"></i>
        </a> */}

        <Header/>
        <Routes>

          <Route path="/" element={
            <>
              <Banner/>
              {/* <SearchBar/> */}
              <EventHighlight/>
              <RecSection/>
              <ConcertSection/>
              <MusicFesSection/>
            </>
          }/>
          <Route path="/tickets" element={<BuyTicketPage/>}/>
          <Route path="/checkout/:id" element={<Checkout/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/edit-profile" element={<Profile/>}/>
          <Route path="/change-password" element={<ChangePass/>}/>
          <Route path="/saved-cards" element={<Cards/>}/>
          <Route path="/my-profile" element={<UserProfile/>}/>
          <Route path="/help-center" element={<MainHelpCenter/>}/>
          <Route path="/post" element={<SinglePage/>}/>
          <Route path="/confirm-order" element={<Confirmation/>}/>
        </Routes>

        <Footer/>
      </Router>
    </>
  );
}

export default App;