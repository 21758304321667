import React from 'react';
import { Link } from 'react-router-dom';

const Register = () => {
  return (
    <section className="account-section bg_img mt-5" data-background="./assets/images/sign-in.jpg">
      <div className="container">
        <div className="padding-top padding-bottom">
          <div className="account-area">
            <div className="section-header-3">
              <span className="cate">welcome</span>
              <h2 className="title main-color">to Ticket4u </h2>
            </div>
            <form className="account-form">
              <div className="form-group">
                <label for="email1" className="main-color">Email <span>*</span></label>
                <input type="text" className="main-color" placeholder="Enter Your Email" id="email1" required/>
              </div>
              <div className="form-group">
                <label for="pass1" className="main-color">Password <span>*</span></label>
                <input type="password" className="main-color" placeholder="Password" id="pass1" required/>
              </div>
              <div className="form-group">
                <label for="pass2" className="main-color">Confirm Password <span>*</span></label>
                <input type="password" className="main-color" placeholder="Password" id="pass2" required/>
              </div>
              <div className="form-group checkgroup">
                <input type="checkbox" id="bal" required checked/>
                <label for="bal">
                  I agree to the <a href="#0">Terms, Privacy Policy</a> and <a href="#0">Fees</a>
                </label>
              </div>
              <div className="form-group text-center">
                <input type="submit" value="Sign Up"/>
              </div>
            </form>
            <div className="option main-color">
              Already have an account? <a href="/login">Login</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
