import React, { useEffect, useState } from "react";
import axios from "axios";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const RecSection = () => {

  const [events, setEvents] = useState([]);

  const fetchEvents = async () => {
    const response = await fetch("https://api.ticket4u.net/api/event/events/");
    if (!response.ok) {
      throw new Error("Failed to fetch events");
    } else {
      const data = await response.json();
      setEvents(data);
      // console.log("Fetched Leader Data", data);
    }
  };
  
  useEffect(() => {
    fetchEvents();
  }, []);

  return (

    <section className="movie-section padding-top padding-bottom">
      <div className="container">
        <div className="tab">
          <div className="section-header-2">
            <div className="left">
              <h2 className="title main-color">Recommended Events</h2>
            </div>
            <ul className="tab-menu">
              <li className="active text-white">Hot Selling</li>
            </ul>
          </div>
          <div className="tab-area mb-30-none">
            <div className="tab-item active">
              {events && events.length > 0 ? (
                <Swiper
                  loop
                  grabCursor={true}
                  slidesPerView={1}
                  spaceBetween={30}
                  speed={1000}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    576:{
                      slidesPerView: 2,
                    },
                    992:{
                      slidesPerView: 3,
                    },
                    1200:{
                      slidesPerView: 4,
                    }
                  }}
                >
                  {events && events.map((event) => (
                    <>
                      <SwiperSlide>
                        <div className="item" key={event.id}>
                          <div className="event-grid" >
                            <div className="movie-thumb c-thumb">
                              <a href={`/checkout/${event.id}`}>
                                <img src={event.banner_image} alt="event" />
                              </a>
                              <div className="event-date">
                                <h6 className="date-title">{event.start_date.split("-")[2]}</h6>
                                <span>
                                  {new Date(event.start_date).toLocaleString("en-US", { month: "short",})}
                                </span>
                              </div>
                            </div>
                            <div className="movie-content bg-one">
                              <h5 className="title m-0">
                                <a href={`/checkout/${event.id}`}>{event.title}</a>
                              </h5>
                              <div className="movie-rating-percent">
                                <span>{event.country.name}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  ))}
                </Swiper>
              ):(
                <div>
                  <p className="text-gray">No events to show.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecSection;
