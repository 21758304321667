import React, { useEffect, useState } from "react";
// import axios from "axios";
import { Link } from 'react-router-dom';

import Banner from '../../assets/images/ticket-cover.jpg';
import BookingIcon from '../../assets/images/icons/book.png';
// import Item1 from '../../assets/images/poster/imagine-dragon.jpg';
// import Item2 from '../../assets/images/poster/joker-xue.jpg';
// import Item3 from '../../assets/images/poster/klwp.jpg';
// import Item4 from '../../assets/images/poster/pinkfish.jpg';
// import Item5 from '../../assets/images/poster/rollingloud.jpg';
// import Item6 from '../../assets/images/poster/neon.jpg';
// import Item7 from '../../assets/images/poster/edc.jpg';
// import Item8 from '../../assets/images/poster/joyland.jpg';
// import Item9 from '../../assets/images/poster/dwp.jpg';

const Tickets = () => {

  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(["All"]);
  const [selectedCountries, setSelectedCountries] = useState(["All"]);

  const fetchEvents = async () => {
    const response = await fetch("https://api.ticket4u.net/api/event/events/");
    if (!response.ok) {
      throw new Error("Failed to fetch events");
    } else {
      const data = await response.json();
      setEvents(data);
      setFilteredEvents(data);
      console.log("Fetched Leader Data", data);
    }
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();
    const weekday = date.toLocaleString("en-US", { weekday: "long" });
    return `${day} ${month} ${year}, ${weekday}`;
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    let hoursInt = parseInt(hours);
    const period = hoursInt >= 12 ? 'PM' : 'AM';
    hoursInt = hoursInt % 12 || 12; // Convert to 12-hour format, with 12 instead of 0
    return `${hoursInt}:${minutes} ${period}`;
  };
  
  useEffect(() => {
    let filtered = [...events];

    // Filter by category if there are any selected
    if (selectedCategories.length > 0 && !selectedCategories.includes("All")) {
      filtered = filtered.filter(event => selectedCategories.includes(event.category.name));
    }

    // Filter by country if there are any selected
    if (selectedCountries.length > 0 && !selectedCountries.includes("All")) {
      filtered = filtered.filter(event => selectedCountries.includes(event.country.name));
    }

    setFilteredEvents(filtered);
  }, [selectedCategories, selectedCountries, events]);

  const handleCategoryChange = (category) => {
    if (category === "All") {
      setSelectedCategories(["All"]);
    } else {
      setSelectedCategories(prevCategories => {
        if (prevCategories.includes(category)) {
          return prevCategories.filter(cat => cat !== category);
        } else {
          return [...prevCategories.filter(cat => cat !== "All"), category];
        }
      });
    }
  };

  const handleCountryChange = (country) => {
    if (country === "All") {
      setSelectedCountries(["All"]);
    } else {
      setSelectedCountries(prevCountries => {
        if (prevCountries.includes(country)) {
          return prevCountries.filter(cntry => cntry !== country);
        } else {
          return [...prevCountries.filter(cntry => cntry !== "All"), country];
        }
      });
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      <section className="banner-section">
        <div className="banner-bg bg_img" data-background={Banner}></div>
        <div className="container">
          <div className="banner-content event-content">
            <h1 className="title bold">get <span className="color-theme">concert</span> tickets today</h1>
            <p className="text-white">Are you still searching for concert tickets? We got it for you!</p>
          </div>
        </div>
      </section>

      <section className="movie-section padding-top padding-bottom">
        <div className="container">
          <div className="row flex-wrap-reverse justify-content-center">
            <div className="col-sm-10 col-md-8 col-lg-3">
              <div className="widget-1 widget-check">
                <div className="widget-header">
                  <h5 className="m-title main-color">Filter By</h5>
                  <button className="clear-check text-white" onClick={() => { setSelectedCategories(["All"]); setSelectedCountries(["All"]); }}>
                    Clear All
                  </button>
                </div>
                <div className="widget-1-body">
                  <h6 className="subtitle">Category</h6>
                  <div className="check-area">
                    <div className="form-group">
                      <input type="checkbox" name="category" id="category1" checked={selectedCategories.includes("All")} onChange={() => handleCategoryChange("All")} />
                      <label htmlFor="category1">All</label>
                    </div>
                    <div className="form-group">
                      <input type="checkbox" name="category" id="category2" checked={selectedCategories.includes("Concert")} onChange={() => handleCategoryChange("Concert")} />
                      <label htmlFor="category2">Concert</label>
                    </div>
                    <div className="form-group">
                      <input type="checkbox" name="category" id="category3" checked={selectedCategories.includes("Music Festivals")} onChange={() => handleCategoryChange("Music Festivals")} />
                      <label htmlFor="category3">Music Festival</label>
                    </div>
                    <div className="form-group">
                      <input type="checkbox" name="category" id="category4" checked={selectedCategories.includes("Sports")} onChange={() => handleCategoryChange("Sports")} />
                      <label htmlFor="category4">Sports</label>
                    </div>
                  </div>
                </div>
                <div className="widget-1-body mt-3">
                  <h6 className="subtitle">Country</h6>
                  <div className="check-area">
                    <div className="form-group">
                      <input type="checkbox" name="country" id="country1" checked={selectedCountries.includes("All")} onChange={() => handleCountryChange("All")} />
                      <label htmlFor="country1">All</label>
                    </div>
                    <div className="form-group">
                      <input type="checkbox" name="country" id="country2" checked={selectedCountries.includes("Malaysia")} onChange={() => handleCountryChange("Malaysia")} />
                      <label htmlFor="country2">Malaysia</label>
                    </div>
                    <div className="form-group">
                      <input type="checkbox" name="country" id="country3" checked={selectedCountries.includes("Thailand")} onChange={() => handleCountryChange("Thailand")} />
                      <label htmlFor="country3">Thailand</label>
                    </div>
                    <div className="form-group">
                      <input type="checkbox" name="country" id="country4" checked={selectedCountries.includes("Indonesia")} onChange={() => handleCountryChange("Indonesia")} />
                      <label htmlFor="country4">Indonesia</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="widget-1 widget-check">
                    <div className="widget-1-body">
                        <h6 className="subtitle">genre</h6>
                        <div className="check-area">
                            <div className="form-group">
                                <input type="checkbox" name="genre" id="genre1"><label for="genre1">thriller</label>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" name="genre" id="genre2"><label for="genre2">horror</label>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" name="genre" id="genre3"><label for="genre3">drama</label>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" name="genre" id="genre4"><label for="genre4">romance</label>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" name="genre" id="genre5"><label for="genre5">action</label>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" name="genre" id="genre6"><label for="genre6">comedy</label>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" name="genre" id="genre7"><label for="genre7">romantic</label>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" name="genre" id="genre8"><label for="genre8">animation</label>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" name="genre" id="genre9"><label for="genre9">sci-fi</label>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" name="genre" id="genre10"><label for="genre10">adventure</label>
                            </div>
                        </div>
                        <div className="add-check-area">
                            <a href="/checkout">view more <i className="plus"></i></a>
                        </div>
                    </div>
              </div> */}
            </div>

            <div className="col-lg-9 mb-50 mb-lg-0">
              <div className="filter-tab tab">
                {/* <div className="filter-area">
                  <div className="filter-main">
                    <div className="left">
                      <div className="item">
                          <span className="show">Show :</span>
                          <select className="select-bar">
                            <option value="12">12</option>
                            <option value="15">15</option>
                            <option value="18">18</option>
                            <option value="21">21</option>
                            <option value="24">24</option>
                            <option value="27">27</option>
                            <option value="30">30</option>
                          </select>
                      </div>
                      <div className="item">
                        <span className="show">Sort By :</span>
                        <select className="select-bar">
                          <option value="showing">Hot Selling</option>
                          <option value="upcoming">upcoming</option>
                        </select>
                      </div>
                    </div>
                    <ul className="grid-button tab-menu">
                      <li>
                        <i className="fas fa-th"></i>
                      </li>                            
                      <li className="active">
                        <i className="fas fa-bars"></i>
                      </li>                            
                    </ul>
                  </div>
                </div> */}
                <div className="tab-area">
                  {/* <div className="tab-item">
                    <div className="row mb-10 justify-content-center">
                      <div className="col-sm-6 col-lg-4">
                        <div className="movie-grid">
                          <div className="movie-thumb c-thumb">
                            <a href="/checkout">
                              <img src="./assets/images/poster/imagine-dragon.jpg" alt="movie" />
                            </a>
                          </div>
                          <div className="movie-content bg-one">
                            <h5 className="title m-0">
                              <a href="movie-details.html">Imagine Drago1ns: LOOM World Tour</a>
                            </h5>
                            <ul className="movie-rating-percent">
                              <li>
                                <div className="thumb">
                                  <i className="fa-solid fa-calendar-days"></i>
                                </div>
                                <span className="content">21 November 2024</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="movie-grid">
                          <div className="movie-thumb c-thumb">
                            <a href="/checkout">
                              <img src="./assets/images/movie/movie01.jpg" alt="movie" />
                            </a>
                          </div>
                          <div className="movie-content bg-one">
                            <h5 className="title m-0">
                              <a href="movie-details.html">alone</a>
                            </h5>
                            <ul className="movie-rating-percent">
                              <li>
                                <div className="thumb">
                                  <i className="fa-solid fa-calendar-days"></i>
                                </div>
                                <span className="content">23 Nov 2024</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="movie-grid">
                          <div className="movie-thumb c-thumb">
                            <a href="/checkout">
                              <img src="./assets/images/movie/movie01.jpg" alt="movie" />
                            </a>
                          </div>
                          <div className="movie-content bg-one">
                            <h5 className="title m-0">
                              <a href="movie-details.html">alone</a>
                            </h5>
                            <ul className="movie-rating-percent">
                              <li>
                                <div className="thumb">
                                  <i className="fa-solid fa-calendar-days"></i>
                                </div>
                                <span className="content">23 Nov 2024</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="movie-grid">
                          <div className="movie-thumb c-thumb">
                            <a href="/checkout">
                              <img src="./assets/images/movie/movie01.jpg" alt="movie" />
                            </a>
                          </div>
                          <div className="movie-content bg-one">
                            <h5 className="title m-0">
                              <a href="movie-details.html">alone</a>
                            </h5>
                            <ul className="movie-rating-percent">
                              <li>
                                <div className="thumb">
                                  <i className="fa-solid fa-calendar-days"></i>
                                </div>
                                <span className="content">23 Nov 2024</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="movie-grid">
                          <div className="movie-thumb c-thumb">
                            <a href="/checkout">
                              <img src="./assets/images/movie/movie01.jpg" alt="movie" />
                            </a>
                          </div>
                          <div className="movie-content bg-one">
                            <h5 className="title m-0">
                              <a href="movie-details.html">alone</a>
                            </h5>
                            <ul className="movie-rating-percent">
                              <li>
                                <div className="thumb">
                                  <i className="fa-solid fa-calendar-days"></i>
                                </div>
                                <span className="content">23 Nov 2024</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div className="movie-grid">
                          <div className="movie-thumb c-thumb">
                            <a href="/checkout">
                              <img src="./assets/images/movie/movie01.jpg" alt="movie" />
                            </a>
                          </div>
                          <div className="movie-content bg-one">
                            <h5 className="title m-0">
                              <a href="movie-details.html">alone</a>
                            </h5>
                            <ul className="movie-rating-percent">
                              <li>
                                <div className="thumb">
                                  <i className="fa-solid fa-calendar-days"></i>
                                </div>
                                <span className="content">23 Nov 2024</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="tab-item active">
                    <div className="movie-area mb-10">
                      {filteredEvents && filteredEvents.length > 0 ? (
                        <>
                          {filteredEvents && filteredEvents.map((event, index) => (
                            <div className="movie-list" key={index}>
                              <div className="movie-thumb c-thumb">
                                <a href={`/checkout/${event.id}`} className="w-100 bg_img h-100" style={{ backgroundImage:`url(${event.banner_image})` }}>
                                  <img className="d-sm-none" src={event.banner_image} alt="movie" />
                                </a>
                              </div>
                              <div className="movie-content bg-one">
                                <h5 className="title">
                                  <a className="main-color" href={`/checkout/${event.id}`}>{event.title}</a>
                                </h5>
                                <p>{event.description}</p>
                                <p className="main-green">{event.category.name}</p>
                                <div className="release mb-0 ">
                                  <span className="font-weight-bold main-color">Organiser: </span>
                                  <span className="main-color">{event.organizer}</span>
                                </div>
                                <div className="release mb-0">
                                  <span className="font-weight-bold main-color">Location: </span>
                                  <span className="main-color">{event.location}</span>
                                </div>
                                <div className="release mb-0">
                                  <span className="font-weight-bold main-color">Date: </span>
                                  <span className="main-color">{formatDate(event.start_date)}</span>
                                </div>
                                <div className="release">
                                  <span className="font-weight-bold main-color">Time: </span>
                                  <span className="main-color">{formatTime(event.start_time)}</span>
                                </div>
                                <div className="book-area">
                                  <div className="book-ticket">
                                    <div className="react-item mr-auto">
                                      <a href={`/checkout/${event.id}`}>
                                        <div className="thumb">
                                          <img src={BookingIcon} alt="icons" />
                                        </div>
                                        <span>Check ticket now</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ):(
                        <div>
                          <p className="text-gray">No events to show.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="pagination-area text-center">
                    <a href="/checkout"><i className="fas fa-angle-double-left"></i><span>Prev</span></a>
                    <a href="/checkout">1</a>
                    <a href="/checkout">2</a>
                    <a href="/checkout" className="active">3</a>
                    <a href="/checkout">4</a>
                    <a href="/checkout">5</a>
                    <a href="/checkout"><span>Next</span><i className="fas fa-angle-double-right"></i></a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tickets;
