import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/css/main.css";
import "./assets/css/new.css";

import "./assets/css/all.min.css"
import "./assets/css/animate.css"
import "./assets/css/flaticon.css"
import "./assets/css/magnific-popup.css"
import "./assets/css/odometer.css"
import "./assets/css/nice-select.css"
import "./assets/css/jquery.animatedheadline.css"

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")  
);