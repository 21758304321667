import React from 'react';
import { Link } from 'react-router-dom';

import SignInBg from '../../../assets/images/sign-in.jpg';

const ChangePass = () => {
  return (
    <>
      <section className="account-section mt-5 pt-5">
        <div className="container m-md-0 p-md-0" style={{ maxWidth: 'none' }}>
          <div className="padding-top padding-bottom d-block d-md-flex">
            <div className="edit-side-bar px-5 pt-5 pb-5 pb-md-0">
              <ul>
                <li>
                  <a href="/edit-profile">Basic Information</a>
                </li>
                <li>
                  <a href="/change-password" className="active">Change Password</a>
                </li>
                <li>
                  <a href="/saved-cards">Saved Cards</a>
                </li>
                <li>
                  <a href="/my-profile">My Tickets</a>
                </li>
              </ul>
            </div>
            <div className="tickets-area account-area">
              <div className="section-header-3">
                <span className="cate font-weight-bold">Change Password</span>
              </div>
              <form className="account-form">
                <div className="form-group">
                  <label for="newpass" className="main-color">New Password <span>*</span></label>
                  <input type="new-password" className="main-color" placeholder="New Password" id="newpass" required/>
                </div>
                <div className="form-group">
                  <label for="confirmpass" className="main-color">Confirm Password <span>*</span></label>
                  <input type="confirm-password" className="main-color" placeholder="Confirm Password" id="confirmpass" required/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>       
    </>
  );
};

export default ChangePass;
