import React from 'react';
import { Link } from 'react-router-dom';

import FooterLogo from '../../assets/images/logo-white.png'

const Footer = () => {
    return (
      <footer className="footer-section">
        <div className="newslater-section padding-bottom">
          <div className="container">
            <div className="newslater-container bg_img mt-5">
              <div className="newslater-wrapper">
                <h5 className="cate">subscribe to Ticket4u</h5>
                <h3 className="title">to get latest updates</h3>
                <form className="newslater-form">
                    <input type="text" placeholder="Your Email Address" />
                    <button type="submit">subscribe</button>
                </form>
                <p>We respect your privacy, so we never share your info.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-top">
            <div className="logo">
              <img className="" style={{maxWidth:"180px"}} src={FooterLogo} alt='Ticket4u Logo'></img>
            </div>
            <ul className="social-icons align-items-center">
              <li>
                <a href="https://www.facebook.com/profile.php?id=61568464150626" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/ticket4u_official/profilecard/?igsh=MXd6eDN6N2Z6cjJuNQ==" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@ticket4u?_t=8rb6kvWBb62&_r=1" target="_blank">
                  <i className="fa-brands fa-tiktok"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-bottom">
            <div className="footer-bottom-area">
              <div className="left">
                <p>
                  Copyright © 2024. All Rights Reserved By{' '}
                  <a href="#">Ticket4u.</a>
                </p>
              </div>
              <ul className="links">
                <li>
                  <a href="#">Terms Of Use</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
};

export default Footer;
