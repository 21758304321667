import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import SignInBg from '../../assets/images/sign-in.jpg';
import Ticket1 from '../../assets/images/poster/joker-xue.jpg';
import Ticket2 from '../../assets/images/poster/imagine-dragon.jpg';
import TestQR from '../../assets/images/test-qr.png';


const UserProfile = () => {

  const [showModal, setShowModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const ticketData = {
    date: '23 Nov 2024',
    name: 'JOKER XUE 薛之谦 "天外来物"海外体育场·返场 - 吉隆坡站',
    location: 'National Stadium Bukit Jalil',
    number: '1 Ticket(s)',
    image: Ticket1,
  };

  const handleOpenModal = (ticket) => {
    setSelectedTicket(ticket);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTicket(null);
  };

  useEffect(() => {
    if (showModal) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Cleanup function to remove the class if the component unmounts
    return () => document.body.classList.remove('no-scroll');
  }, [showModal]);

  return (
    <>
      <section className="account-section mt-5 pt-5">
        <div className="container m-md-0 p-md-0" style={{ maxWidth: 'none' }}>
          <div className="padding-top padding-bottom d-block d-md-flex">
            <div className="edit-side-bar px-5 pt-5 pb-5 pb-md-0">
              <ul>
                <li>
                  <a href="/edit-profile">Basic Information</a>
                </li>
                <li>
                  <a href="/change-password">Change Password</a>
                </li>
                <li>
                  <a href="/saved-cards">Saved Cards</a>
                </li>
                <li>
                  <a href="/my-profile" className="active">My Tickets</a>
                </li>
              </ul>
            </div>
            <div className="tickets-area">
              <div className="section-header-3">
                <span className="cate font-weight-bold">Your tickets</span>
                <h2 className="title main-color">Michael Lang</h2>
              </div>
              <div className="tickets-container mb-4" onClick={() => handleOpenModal(ticketData)}>
                <div className="tickets-wrapper d-block d-sm-flex align-items-center">
                  <img className="w-100 w-sm-50" src={Ticket1} />
                  <div className="ticket-info text-white py-5 py-sm-0">
                    <p className="ticket-date">{ticketData.date}</p>
                    <p className="ticket-name">{ticketData.name}</p>
                    <p className="ticket-location">{ticketData.location}</p>
                    <p className="ticket-number">{ticketData.number}</p>
                  </div>
                </div>
              </div>
              <div className="tickets-container mb-4">
                <div className="tickets-wrapper d-block d-sm-flex align-items-center">
                  <img className="w-100 w-sm-50" src={Ticket2} />
                  <div className="ticket-info text-white py-5 py-sm-0">
                    <p className="ticket-date">21 Nov 2024</p>
                    <p className="ticket-name">Imagine Dragons: LOOM World Tour</p>
                    <p className="ticket-location">National Stadium Bukit Jalil</p>
                    <p className="ticket-number">3 Ticket(s)</p>
                  </div>
                </div>
              </div>

              {showModal && (
                <div className="modal-overlay">
                  <div className="position-relative mx-auto">
                    <button className="modal-close" onClick={handleCloseModal}>
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                    <div className="modal-body ticket-information bg-one">
                      <h4 className="title">Ticket</h4>
                      <ul>
                        <li>
                          <img src={TestQR} />
                        </li>
                        <li>
                          <h6 className="subtitle">Venus</h6>
                          <span className="info">English-2d</span>
                        </li>
                        <li>
                          <h6 className="subtitle"><span>City Walk</span><span>02</span></h6>
                          <div className="info"><span>10 SEP TUE, 11:00 PM</span> <span>Tickets</span></div>
                        </li>
                        <li>
                          <h6 className="subtitle mb-0"><span>Tickets  Price</span><span>$150</span></h6>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <span className="info"><span>price</span><span>$207</span></span>
                          <span className="info"><span>vat</span><span>$15</span></span>
                        </li>
                      </ul>
                    </div>
                  </div> 
                </div>
              )}
            </div>
          </div>
        </div>
      </section>       
    </>
  );
};

export default UserProfile;


          