import React from 'react';
import { Link } from 'react-router-dom';

import Author from '../../assets/images/blog/author.jpg';

const MainHelpCenter = () => {
  return (
    <>
      <section className="main-page-header speaker-banner bg_img" data-background=''>
        <div className="container">
          <div className="speaker-banner-content">
            <h2 className="title">Help Center</h2>
          </div>
        </div>
      </section>

      <section className="blog-section padding-top padding-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 mb-50 mb-lg-0">
              <article>
                <div className="post-item">
                  {/* <div className="post-thumb">
                      <a href="/post">
                          <img src="" alt="blog"/>
                      </a>
                  </div> */}
                  <div className="post-content">
                    <div className="post-header">
                      <h4 className="title">
                        <a href="/post">
                          Guide for purchasing tickets on Ticket4U
                        </a>
                      </h4>
                      {/* <div className="meta-post">
                        <a href="#0" className="mr-4"><i className="flaticon-conversation"></i>20 Comments</a>
                        <a href="#0"><i className="flaticon-view"></i>466 View</a>
                      </div> */}
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac cursus leo. Nullam dolor nunc, hendrerit non velit id, pharetra viverra elit.
                      </p>
                    </div>
                    <div className="entry-content">
                      <div className="left">
                        <span className="date">Dece 15, 2020</span>
                        {/* <div className="authors">
                          <div className="thumb">
                            <a href="#0"><img src={Author} alt="#0"/></a>
                          </div>
                          <h6 className="title"><a href="#0">Alvin Mcdaniel</a></h6>
                        </div> */}
                      </div>
                      <a href="/post" className="buttons">Read More <i className="flaticon-right"></i></a>
                    </div>
                  </div>
                </div>   
              </article>
              <div className="pagination-area text-center">
                <a href="#0"><i className="fas fa-angle-double-left"></i><span>Prev</span></a>
                <a href="#0">1</a>
                <a href="#0">2</a>
                <a href="#0" className="active">3</a>
                <a href="#0">4</a>
                <a href="#0">5</a>
                <a href="#0"><span>Next</span><i className="fas fa-angle-double-right"></i></a>
              </div>
            </div>
            <div className="col-lg-4 col-sm-10 col-md-8">
              <aside>
                {/* <div className="widget widget-search">
                  <h5 className="title">search</h5>
                  <form className="search-form">
                    <input type="text" placeholder="Enter your Search Content" required/>
                    <button type="submit"><i className="flaticon-loupe"></i>Search</button>
                  </form>
                </div>
                <div className="widget widget-post">
                  <h5 className="title">latest post</h5>
                  <div className="slider-nav">
                    <span className="flaticon-angle-pointing-to-left widget-prev"></span>
                    <span className="flaticon-right-arrow-angle widget-next active"></span>
                  </div>
                  <div className="widget-slider owl-carousel owl-theme">
                    <div className="item">
                      <div className="thumb">
                        <a href="#0">
                          <img src="./assets/images/blog/slider01.jpg" alt="blog"/>
                        </a>
                      </div>
                      <div className="content">
                        <h6 className="p-title">
                          <a href="#0">Three Ways to Book Sporting Event Tickets</a>
                        </h6>
                        <div className="meta-post">
                          <a href="#0" className="mr-4"><i className="flaticon-loupe"></i>20 Comments</a>
                          <a href="#0"><i className="flaticon-loupe"></i>466 View</a>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="thumb">
                        <a href="#0">
                          <img src="./assets/images/blog/slider01.jpg" alt="blog"/>
                        </a>
                      </div>
                      <div className="content">
                        <h6 className="p-title">
                          <a href="#0">Three Ways to Book Sporting Event Tickets</a>
                        </h6>
                        <div className="meta-post">
                          <a href="#0" className="mr-4"><i className="flaticon-loupe"></i>20 Comments</a>
                          <a href="#0"><i className="flaticon-loupe"></i>466 View</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget widget-follow">
                  <h5 className="title">Follow Us</h5>
                  <ul className="social-icons">
                    <li>
                      <a href="#0" className="">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0" className="active">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0" className="">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <i className="fab fa-google"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="widget widget-categories">
                  <h5 className="title">Quick Help</h5>
                  <ul>
                    <li>
                      <a href="#0">
                        <span>How to buy tickets?</span>
                        {/* <span>50</span> */}
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <span>Where are my tickets?</span>
                        {/* <span>43</span> */}
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <span>How to use e-tickets?</span>
                        {/* <span>34</span> */}
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="widget widget-tags">
                  <h5 className="title">featured tags</h5>
                  <ul>
                    <li>
                      <a href="#0">creative</a>
                    </li>
                    <li>
                      <a href="#0">design</a>
                    </li>
                    <li>
                      <a href="#0">skill</a>
                    </li>
                    <li>
                      <a href="#0">template</a>
                    </li>
                    <li>
                      <a href="#0" className="active">landing</a>
                    </li>
                  </ul>
                </div> */}
              </aside>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainHelpCenter;