import React from 'react';
import { Link } from 'react-router-dom';


const Confirmation = () => {
  return (
    <>
      <section className="confirmation-section mt-5 padding-top padding-bottom">
        <div className="container-fluid mt-5 mt-mb-0 text-center">
          <div className="col-lg-8 mx-auto mb-5">
            <h3 className="mb-3 main-color">You're all set!</h3>
            <div className="order-confirmation">
              <i className="fa-sharp fa-light fa-receipt mb-3 main-color"></i>
              <h6 className="mb-2">Order confirmation</h6>
              <p className="mb-3">Order ID 9AS8D7SKH</p>
            </div>
            <div className="order-view-ticket">
              <button>View my tickets</button>
              <p className="text-lowercase">or open the <a href="#">email attachment</a></p>
            </div>
          </div>
          <div className="col-lg-10 mx-auto">
            <div className="order-information bg-one">
              <h4 className="title">Order Information</h4>
              <ul>
                <li>
                  <h6 className="subtitle">Venus</h6>
                  <span className="info">English-2d</span>
                </li>
                <li>
                  <h6 className="subtitle"><span>City Walk</span><span>02</span></h6>
                  <div className="info"><span>10 SEP TUE, 11:00 PM</span> <span>Tickets</span></div>
                </li>
                <li>
                  <h6 className="subtitle mb-0"><span>Tickets  Price</span><span>$150</span></h6>
                </li>
              </ul>
              {/* <ul className="side-shape">
                <li>
                  <h6 className="subtitle"><span>combos</span><span>$57</span></h6>
                  <span className="info"><span>2 Nachos Combo</span></span>
                </li>
                <li>
                  <h6 className="subtitle"><span>food & bevarage</span></h6>
                </li>
              </ul> */}
              <ul>
                <li>
                  <span className="info"><span>price</span><span>$207</span></span>
                  <span className="info"><span>vat</span><span>$15</span></span>
                </li>
              </ul>
            </div>
          </div>          
        </div>
      </section>
    </>
  );
};

export default Confirmation;
