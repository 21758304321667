import React from 'react';
import { Link } from 'react-router-dom';

import HeaderLogo from '../../assets/images/logo.png'


const Header = () => {
  return (
    <header className="header-section">
      <div className="container">
        <div className="header-wrapper">
          <div className="logo">
            <a href="/">
              <img className="w-75" src={HeaderLogo} alt='Ticket4u Logo'></img>
            </a>
          </div>
          <ul className="menu">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/tickets">Buy Tickets</a>
            </li>
            <li>
              <a href="/login">Login</a>
            </li>
            <li>
              <a href="#">Profile</a>
              <ul className="submenu">
                <li>
                  <a href="/my-profile">Profile Info</a>
                </li>
                <li>
                  <a href="/edit-profile">Edit Profile</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/help-center">Help Center</a>
            </li>
          </ul>
          <div className="header-bar d-lg-none">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>                                
      </div>
    </header>
  );
};

export default Header;
