import React from 'react';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import Highlight1 from '../../assets/images/event-highlight/highlight1.jpeg'

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const EventHighlight = () => {
  return (
    <div className="highlights-section mt-5">
      <div className="event-item active">
        <div className="owl-carousel owl-theme event-slider">
          <Swiper
            loop
            navigation={{ clickable: true }}
            grabCursor={true}
            slidesPerView={1}
            speed={1000}
            autoplay={{
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <div className="item">
                <div className="m-0 px-4">
                  <img src={Highlight1} className="w-100"/>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="item">
                <div className="m-0 px-4">
                  <img src={Highlight1} className="w-100"/>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="item">
                <div className="m-0 px-4">
                  <img src={Highlight1} className="w-100"/>
                </div>
              </div>
            </SwiperSlide>

          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default EventHighlight;
