import React from 'react';
import { Link } from 'react-router-dom';

const Login = () => {
  return (
    <section className="account-section bg_img mt-5" data-background="./assets/images/sign-in.jpg">
      <div className="container">
        <div className="padding-top padding-bottom">
          <div className="account-area">
            <div className="section-header-3">
              <span className="cate">hello</span>
              <h2 className="title main-color">welcome back</h2>
            </div>
            <form className="account-form">
              <div className="form-group">
                <label for="email2" className="main-color">Email <span>*</span></label>
                <input type="text" className="main-color" placeholder="Enter Your Email" id="email2" required/>
              </div>
              <div className="form-group">
                <label for="pass3" className="main-color">Password <span>*</span></label>
                <input type="password" className="main-color" placeholder="Password" id="pass3" required/>
              </div>
              {/* <div className="form-group checkgroup">
                <input type="checkbox" id="bal2" required checked>
                <label for="bal2">remember password</label>
                <a href="#0" className="forget-pass">Forget Password</a>
              </div> */}
              <div className="form-group text-center">
                <input type="submit" value="log in"/>
              </div>
            </form>
            <div className="option main-color">
              Don't have an account? <a href="/register">sign up now</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
