import React from 'react';

import TicketCover from '../../assets/images/ticket-cover.jpg';

const Banner = () => {

  return (
    <section className="banner-section">
      <div className="banner-bg banner_bg_img" data-background={TicketCover}></div>
      <div className="container">
        <div className="banner-content">
          <p className="text-white">Need last minute ticket purchase?</p>
          <h1 className="title cd-headline clip">
            <span className="color-theme cd-words-wrapper p-0 m-0">
              <b className="is-visible">Concert</b>
              <b>Music Festival</b>
            </span>
          </h1>
          <p className="text-white">Safe & reliable reseller tickets just 4 you!</p>
        </div>
      </div>
    </section>
  );
};

export default Banner;
