import React from 'react';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

import SignInBg from '../../../assets/images/sign-in.jpg';

const Profile = () => {
  return (
    <>
      <section className="account-section mt-5 pt-5">
        <div className="container m-md-0 p-md-0" style={{ maxWidth: 'none' }}>
          <div className="padding-top padding-bottom d-block d-md-flex">
            <div className="edit-side-bar px-5 pt-5 pb-5 pb-md-0">
              <ul>
                <li>
                  <a href="/edit-profile" className="active">Basic Information</a>
                </li>
                <li>
                  <a href="/change-password">Change Password</a>
                </li>
                <li>
                  <a href="/saved-cards">Saved Cards</a>
                </li>
                <li>
                  <a href="/my-profile">My Tickets</a>
                </li>
              </ul>
            </div>
            <div className="tickets-area account-area">
              <div className="section-header-3">
                <span className="cate font-weight-bold">Edit Profile</span>
              </div>
              <form className="account-form">
                <div className="form-group">
                  <label for="email1" className="main-color">Email</label>
                  <input type="text" className="main-color" placeholder="mlb@email.com" id="email1"/>
                </div>
                <div className="form-group">
                  <label for="firstname" className="main-color">First Name</label>
                  <input type="text" className="main-color" placeholder="Michael" id="firstname"/>
                </div>
                <div className="form-group">
                  <label for="lastname" className="main-color">Last Name</label>
                  <input type="text" className="main-color" placeholder="Lang" id="lastname"/>
                </div>
                <div className="form-group">
                  <label for="phone" className="main-color">Phone</label>
                  <input type="number" className="main-color" placeholder="012-3456789" id="phone"/>
                </div>
                <div className="form-group text-center">
                  <input type="submit" className="main-color" value="Save"/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
