import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Ticket1 from '../../assets/images/event/ticket/ticket01.png';
import Ticket2 from '../../assets/images/event/ticket/ticket02.png';
import Ticket3 from '../../assets/images/event/ticket/ticket03.png';
import SeatMap from '../../assets/images/seat-map.png';


const Checkout = () => {

  const { id } = useParams(); // Get the event ID from the URL
  const [event, setEvent] = useState([null]);
  const [loading, setLoading] = useState(false);


  const fetchEventDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.ticket4u.net/api/event/events/${id}/`);
      // const response = await fetch(`https://api.ticket4u.net/api/event/events/6/`);
      if (!response.ok) throw new Error("Failed to fetch events");
    
        const data = await response.json();
        setEvent(data);
        console.log("Fetched Leader Data", data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    // console.log('Fetching event details for ID:', id);
    if (id) fetchEventDetails();
  }, [id]);

  // useEffect(() => {
  //   fetchEventDetails();
  // }, []);

  const formatDateShort = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    const weekday = date.toLocaleString("en-US", { weekday: "short" });
    return `${weekday}, ${day} ${month} ${year}`;
  };

  const formatDateShorter = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const weekday = date.toLocaleString("en-US", { weekday: "short" });
    return `${day} ${month} ${weekday}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();
    const weekday = date.toLocaleString("en-US", { weekday: "long" });
    return `${day} ${month} ${year}, ${weekday}`;
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    let hoursInt = parseInt(hours);
    const period = hoursInt >= 12 ? 'PM' : 'AM';
    hoursInt = hoursInt % 12 || 12; // Convert to 12-hour format, with 12 instead of 0
    return `${hoursInt}:${minutes} ${period}`;
  };

  // useEffect(() => {
  //   fetchEventDetails();
  // }, []);

  return (
    <>
      <section className="details-banner event-details-banner hero-area bg_img seat-plan-banner" data-background={event.banner_image}>
        <div className="container">
          <div className="details-banner-wrapper">
            <div className="details-banner-content style-two">
              <h3 className="title">
                <span className="d-block">{event.title}</span> 
              </h3>
              <div className="tags">
                <span>{event.location}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-title bg-one">
        <div className="container">
          <div className="page-title-area">
            <div className="item md-order-1">
              <a href="/tickets" className="custom-button back-button">
                <i className="flaticon-double-right-arrows-angles"></i>back
              </a>
            </div>
            <div className="item date-item">
              <span className="date text-uppercase">{formatDateShort(event.start_date)}</span>
            </div>
            {/* <div className="item">
              <h5 className="title">05:00</h5>
              <p>Mins Left</p>
            </div> */}
          </div>
        </div>
      </section>

      <div className="event-facility padding-bottom padding-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="checkout-widget d-flex flex-wrap align-items-center justify-cotent-between">
                <div className="title-area">
                  <h5 className="title">Seat Map</h5>
                </div>
                <img className="w-100" src={event.sit_map}/>
              </div>
              <div className="checkout-widget checkout-contact">
                <div className="title-area">
                  <h5 className="title">{event.title}</h5>
                </div>
                <p>{event.description}</p>                  
                <div className="release mb-0">
                  <span className="font-weight-bold">Organiser: </span>{event.organizer}
                </div>
                <div className="release mb-0">
                  <span className="font-weight-bold">Location: </span>{event.location}
                </div>
                <div className="release mb-0">
                  <span className="font-weight-bold">Date: </span>{formatDate(event.start_date)}
                </div>
                <div className="release">
                  <span className="font-weight-bold">Time: </span>{event.start_time ? formatTime(event.start_time) : 'N/A'}

                </div>
              </div>
              <div className="checkout-widget checkout-contact">
                <h5 className="title">Get Your Tickets</h5>
                <div className="ticket--area row justify-content-center">
                  {event.ticket_types && event.ticket_types.length > 0 ? (
                    event.ticket_types.map((ticket) => (
                      <div className="col-sm-6 col-md-4" key={ticket.id}>
                        <div className="ticket-item">
                          <div className="ticket-thumb">
                            <img src={Ticket1} alt="event"/>
                          </div>
                          <div className="ticket-content">
                            <span className="ticket-title">{ticket.name}</span>
                            <h2 className="amount">RM{ticket.ticket_price}</h2>
                            <a href="#0" className="t-button">
                                <i className="fas fa-plus"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No ticket types available for this event.</p>
                  )}
                </div>
                <div className="row mb-30-none">
                  <div className="col-md-6 col-xl-5">
                    <form className="cart-button event-cart">
                      <span className="d-inline-block">Number of Seats : </span>
                      <div className="cart-plus-minus"><div className="dec qtybutton">-</div>
                        <input className="cart-plus-minus-box" type="text" name="qtybutton" value="2" readOnly/>
                      <div className="inc qtybutton">+</div></div>
                    </form>
                  </div>
                  <div className="col-md-6 col-xl-7">
                    <form className="checkout-contact-form mb-0">
                      <div className="form-group">
                        <input type="text" placeholder="Please enter promo code"/>
                      </div>
                      <div className="form-group">
                        <input type="submit" value="Verify" className="custom-button"/>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* <div className="checkout-widget checkout-card mb-0">
                  <h5 className="title">Payment Option </h5>
                  <ul className="payment-option">
                      <li className="active">
                          <a href="#0">
                              <img src="./assets/images/payment/card.png" alt="payment">
                              <span>Credit Card</span>
                          </a>
                      </li>
                      <li>
                          <a href="#0">
                              <img src="./assets/images/payment/card.png" alt="payment">
                              <span>Debit Card</span>
                          </a>
                      </li>
                      <li>
                          <a href="#0">
                              <img src="./assets/images/payment/paypal.png" alt="payment">
                              <span>paypal</span>
                          </a>
                      </li>
                  </ul>
                  <h6 className="subtitle">Enter Your Card Details </h6>
                  <form className="payment-card-form">
                      <div className="form-group w-100">
                          <label for="card1">Card Details</label>
                          <input type="text" id="card1">
                          <div className="right-icon">
                              <i className="flaticon-lock"></i>
                          </div>
                      </div>
                      <div className="form-group w-100">
                          <label for="card2"> Name on the Card</label>
                          <input type="text" id="card2">
                      </div>
                      <div className="form-group">
                          <label for="card3">Expiration</label>
                          <input type="text" id="card3" placeholder="MM/YY">
                      </div>
                      <div className="form-group">
                          <label for="card4">CVV</label>
                          <input type="text" id="card4" placeholder="CVV">
                      </div>
                      <div className="form-group check-group">
                          <input id="card5" type="checkbox" checked>
                          <label for="card5">
                              <span className="title">QuickPay</span>
                              <span className="info">Save this card information to my Boleto  account and make faster payments.</span>
                          </label>
                      </div>
                      <div className="form-group">
                          <input type="submit" className="custom-button" value="make payment">
                      </div>
                  </form>
                  <p className="notice">
                      By Clicking "Make Payment" you agree to the <a href="#0">terms and conditions</a>
                  </p>
              </div> */}
            </div>
            <div className="col-lg-4">
              <div className="booking-summery bg-one">
                <h4 className="title">booking summary</h4>
                <ul>
                  <li>
                    <h6 className="subtitle">{event.title}</h6>
                    <span className="info">{event.location}</span>
                  </li>
                  <li>
                    {/* <h6 className="subtitle"><span>{event.ticket_types.name ? event.ticket_types.name : 'N/A'}</span><span>02</span></h6> */}
                    <div className="info"><span>{formatDateShorter(event.start_date)}, {event.start_time ? formatTime(event.start_time) : 'N/A'}</span> <span>Tickets</span></div>
                  </li>
                  <li>
                    <h6 className="subtitle mb-0"><span>Tickets  Price</span><span>$150</span></h6>
                  </li>
                </ul>
                {/* <ul className="side-shape">
                  <li>
                    <h6 className="subtitle"><span>combos</span><span>$57</span></h6>
                    <span className="info"><span>2 Nachos Combo</span></span>
                  </li>
                  <li>
                    <h6 className="subtitle"><span>food & bevarage</span></h6>
                  </li>
                </ul> */}
                <ul>
                  <li>
                    <span className="info"><span>price</span><span>$207</span></span>
                    <span className="info"><span>vat</span><span>$15</span></span>
                  </li>
                </ul>
              </div>
              <div className="proceed-area  text-center">
                <h6 className="subtitle"><span>Amount Payable</span><span>$222</span></h6>
                <a href="/confirm-order" className="custom-button back-button">proceed</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
