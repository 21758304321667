import React from 'react';
import { Link } from 'react-router-dom';

import SignInBg from '../../../assets/images/sign-in.jpg';
import Mastercard from '../../../assets/images/mastercard.png';
import Visa from '../../../assets/images/visa.png';

const Cards = () => {
  return (
    <>
      <section className="account-section mt-5 pt-5">
        <div className="container m-md-0 p-md-0" style={{ maxWidth: 'none' }}>
          <div className="padding-top padding-bottom d-block d-md-flex">
            <div className="edit-side-bar px-5 pt-5 pb-5 pb-md-0">
              <ul>
                <li>
                  <a href="/edit-profile">Basic Information</a>
                </li>
                <li>
                  <a href="/change-password">Change Password</a>
                </li>
                <li>
                  <a href="/saved-cards" className="active">Saved Cards</a>
                </li>
                <li>
                  <a href="/my-profile">My Tickets</a>
                </li>
              </ul>
            </div>
            <div className="tickets-area account-area text-center">
              <div className="section-header-3">
                <span className="cate font-weight-bold">Saved Cards</span>
              </div>
              <div className="saved-cards-container mb-4">
                <div className="saved-cards-wrapper d-flex justify-content-between align-items-center">
                  <img className="w-25" src={Mastercard} alt="Mastercard" />
                  <p className="text-white">1234 5678 9012 3456</p>
                </div>
              </div>
              <div className="saved-cards-container mb-4">
                <div className="saved-cards-wrapper d-flex justify-content-between align-items-center">
                  <img className="w-25" src={Visa} alt="Visa" />
                  <p className="text-white">1234 5678 9012 3456</p>
                </div>
              </div>
              <button className="add-new-card text-white">Add a new card</button>
            </div>
          </div>
        </div>
      </section>       
    </>
  );
};

export default Cards;
